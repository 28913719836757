<template>
    <v-container fluid>
        <v-form @submit.prevent="getIndividualNotifications('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'individual_notification.create'}"
                   color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_individual_notification') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'individual_notification.create'}" class="white--text" color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="title" rules="min:3"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="title" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-notification-clear-all"
                                                  :label="$t('push_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)" hide-details
                                          dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="individual_notificationItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalIndividualNotifications" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              single-expand :expanded.sync="expanded" item-key="id" show-expand hide-default-footer>
                    <template v-slot:item.created_at="{ item }">
                        {{ $moment(item.created_at).format('YYYY-MM-DD HH:mm') }}
                    </template>
                    <template v-slot:item.subtract_days="{ item }">
                        <div >
                            {{ item.subtract_days === 0 ? $t('on_my_birthday') : item.subtract_days }}
                        </div>

                    </template>

                        <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editIndividualNotifications(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteIndividualNotifications(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:expanded-item="{ headers, item}">
                        <td class="pt-4" :colspan="headers.length">
                            <v-row justify="center" class="px-3">
                                <v-col cols="12" class="justify-start">
                                    <p>{{ $t('push_name') }}: {{ item.title }}</p>
                                    <p>{{ $t('body') }}: {{ item.body }}</p>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePage"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: "IndividualNotifications",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                expanded: [],
                title: null,
                sortBy: "created_at",
                sortDir: false,
                loading: false,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePage: 5,
                totalIndividualNotifications: 0,
                individual_notificationItems: [],
                dateIndividualNotificationsFrom: false,
                dateTo: null,
                dateIndividualNotificationsTo: false,
                headers: [
                    {
                        text: '',
                        value: 'data-table-expand',
                        width: 40,
                        class: "handle",
                    },
                    {
                        text: this.$t('push_name'),
                        align: "left",
                        sortable: true,
                        value: "title"
                    },

                    {
                        text: this.$t('subtract_days'),
                        align: "center",
                        sortable: true,
                        value: "subtract_days"
                    },
                    {
                        text: this.$t('time'),
                        align: "center",
                        sortable: true,
                        value: "time"
                    }, {
                        text: this.$t('date_created'),
                        align: "center",
                        sortable: true,
                        value: "created_at"
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getIndividualNotifications()
                },
                deep: false
            }
        },
        methods: {
            editIndividualNotifications(item) {
                this.$router.push({
                    name: 'individual_notification.edit',
                    params: {
                        id: item.id
                    }
                })
            },

            async getIndividualNotifications(type) {
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options

                let params = {}

                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0]
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc'
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage
                }
                if (this.title) {
                    params.title = this.title
                }

                await this.$http
                    .get("admin/individual_notification", {
                        params: params,
                    })
                    .then(res => {
                        this.individual_notificationItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalIndividualNotifications = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.individual_notificationItems = []
                        this.totalIndividualNotifications = 0
                        this.$toastr.error(this.$t('failed_to_get_list_individual_notification'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async deleteIndividualNotifications(item) {
                if (confirm(this.$t('delete_individual_notification'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/individual_notification/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('individual_notification_has_been_deleted'))
                            this.getIndividualNotifications()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('individual_notification_has_not_been_deleted'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
        }
    }
</script>
